import React from "react";
import { Container } from "react-bootstrap";
// import "./Governance.css";
import "../../CSS/Governance.css";
import pdf1 from "../../Assets/Legal/12 A permenant.pdf";
import pdf2 from "../../Assets/Legal/80G permanant.pdf";
import pdf3 from "../../Assets/Legal/NDES  CERTIFIATE.pdf";
import pdf4 from "../../Assets/Legal/CSR CERTIFICATE.pdf";
import pdf5 from "../../Assets/Legal/NDES PAN CARD.pdf";

import pdf6 from "../../Assets/auditreports/2013-14 F.Y.pdf";
import pdf7 from "../../Assets/auditreports/2014-15 F.Y.pdf";
import pdf8 from "../../Assets/auditreports/2015-16 F.Y.pdf";
import pdf9 from "../../Assets/auditreports/2016-17 F.Y.pdf";
import pdf10 from "../../Assets/auditreports/2017-18 F.Y.pdf";
import pdf11 from "../../Assets/auditreports/2018-19 F.Y.pdf";
import pdf12 from "../../Assets/auditreports/2019-20 F.Y.pdf";
import pdf13 from "../../Assets/auditreports/2020-21 F.Y.pdf";
import pdf from "../../Assets/annualreports/Red and White Video Centric Coming Soon Instagram Post.png";
import MetaTags from "../MetaInfo/MetaTags";

const Governance = () => {
  const openPdf = (pdf) => {
    window.open(pdf, "_blank");
  };

  const color = {
    color: "#F04841",
  };
  return (
    <>
      <MetaTags
        title={
          "Governance and Accountability | ND Skills - Ensuring Excellence"
        }
        Description={
          "Explore ND Skills' governance, accountability, and commitment to transparency in skill training and community development"
        }
        Keywords={
          "Governance  NDES governance  Governance at ND Skills  Skill training governance  Accountability and transparency in NDES"
        }
        href={"https://ndskills.in/governace"}
      />
      <Container>
        <h1 style={color} className="text-center p-3" id="heading">
          GOVERNANCE
        </h1>
        <p>
          New Directions Educational Society ( NDES) is registered as a Society
          under Andhra Pradesh Societies Registration Act 2001. Donations from
          India to<span style={color}> New Directions Educational Society</span>{" "}
          are eligible for tax exemption under Section 80G of the Income Tax
          Act. NDES is also registered with the Govt. of India's NITI Aayog
          (TS/201B/0197015).
          <p>
            We are also a CSR Implementing Agency , registered with the MCA &
            Also a registered PIA for the MORD , working in the implementation
            of the DDU-GKY program.
          </p>
        </p>
        <h2>Accountability</h2>
        <p>
          NDES is committed to the cause of community development through Skill
          Training, Women Empowerment and Advocacy of Environmental Issues while
          abiding by the strictest codes of accountability and transparency.
          Governed by a Board which closely monitors and regulates our fund
          allocation & management processes.
        </p>
        <h2>Legal</h2>
        <ul>
          <li>
            <span
              style={color}
              onClick={() => {
                openPdf(pdf3);
              }}
              className="span-style"
            >
              Society Registration
            </span>
          </li>
          <li>
            <span
              style={color}
              onClick={() => {
                openPdf(pdf1);
              }}
              className="span-style"
            >
              12A Registration Certificate
            </span>
          </li>
          <li>
            <span
              style={color}
              onClick={() => {
                openPdf(pdf2);
              }}
              className="span-style"
            >
              80 G Certificate
            </span>
          </li>
          <li>
            <span
              style={color}
              onClick={() => {
                openPdf(pdf5);
              }}
              className="span-style"
            >
              PAN Card
            </span>
          </li>
          <li>
            <span
              style={color}
              onClick={() => {
                openPdf(pdf4);
              }}
              className="span-style"
            >
              CSR 1 Registration Certificate
            </span>
          </li>
        </ul>
        <h2>Financial Reporting</h2>
        <ul>
          {/* <li>
            <span
              style={color}
              onClick={() => {
                openPdf(pdf6);
              }}
            >
              Audited Financial Report 2021-2022
            </span>
          </li> */}
          <li>
            <span
              style={color}
              onClick={() => {
                openPdf(pdf13);
              }}
              className="span-style"
            >
              Audited Financial Report 2020-2021
            </span>
          </li>
          <li>
            <span
              style={color}
              onClick={() => {
                openPdf(pdf12);
              }}
              className="span-style"
            >
              Audited Financial Report 2019-2020
            </span>
          </li>
          <li>
            <span
              style={color}
              onClick={() => {
                openPdf(pdf11);
              }}
              className="span-style"
            >
              Audited Financial Report 2018-2019
            </span>
          </li>
          <li>
            <span
              style={color}
              onClick={() => {
                openPdf(pdf10);
              }}
              className="span-style"
            >
              Audited Financial Report 2017-2018
            </span>
          </li>
          <li>
            <span
              style={color}
              onClick={() => {
                openPdf(pdf9);
              }}
              className="span-style"
            >
              Audited Financial Report 2016-2017
            </span>
          </li>
          <li>
            <span
              style={color}
              onClick={() => {
                openPdf(pdf8);
              }}
              className="span-style"
            >
              Audited Financial Report 2015-2016
            </span>
          </li>
          <li>
            <span
              style={color}
              onClick={() => {
                openPdf(pdf7);
              }}
              className="span-style"
            >
              Audited Financial Report 2014-2015
            </span>
          </li>
          <li>
            <span
              style={color}
              onClick={() => {
                openPdf(pdf6);
              }}
              className="span-style"
            >
              Audited Financial Report 2013-2014
            </span>
          </li>
          {/* <li>
            <span
              style={color}
              
              onClick={() => {
                openPdf();
              }}
            >
              Audited Financial Report 2012-2013
            </span>
          </li>

          <li>
            <span
              style={color}
              
              onClick={() => {
                openPdf();
              }}
            >
              Audited Financial Report 2011-2012
            </span>
          </li> */}
        </ul>

        <h2>Annual Reports</h2>
        <p>
          Below are our Annual Reports for the financial years 2010-12 and
          2019-20! The reports collate our work throughout the year and
          summarise the impact of our programmes.
        </p>
        <p>
          To download the PDF version of the 2021-22 report
          <span
            style={color}
            onClick={() => {
              openPdf(pdf);
            }}
            className="span2-style"
          >
            {" "}
            click here
          </span>
        </p>
        <p>
          To download the PDF version of the 2020-21 report
          <span
            style={color}
            onClick={() => {
              openPdf(pdf);
            }}
            className="span2-style"
          >
            {" "}
            click here{" "}
          </span>
        </p>
        <p>
          To download the PDF version of the 2019-20 report
          <span
            onClick={() => {
              openPdf(pdf);
            }}
            style={color}
            className="span2-style"
          >
            {" "}
            click here{" "}
          </span>
        </p>
        <p>
          To download the PDF version of the 2018-19 report
          <span
            style={color}
            onClick={() => {
              openPdf(pdf);
            }}
            className="span2-style"
          >
            {" "}
            click here{" "}
          </span>
        </p>

        <h2>Code of Ethics</h2>
        <p>
          Our volunteers, employees and representatives have a responsibility to
          uphold the highest ethical standards. We believe these standards go
          beyond compliance with laws and regulations.NDES underpins its
          functioning on the following key principles:
        </p>
        <p>
          a) Honesty b) Integrity c) Transparency d) Confidentiality e) Respect
          f) Responsibility g) Fairness
        </p>
        <p>
          We ensure the Implementation of our Principles by Regular EDUCATION
          AND TRAINING sessions to ensure understanding and adherence
        </p>
        <p>
          {" "}
          <span className=" fw-medium">Communication :</span> Clear
          communication of the code to all members
        </p>
        <p>
          <span className=" fw-medium">Enforcement :</span> We are constantly
          reviewing and strengthening mechanisms to report violations and
          strengthen the code. & also conduct regular updates and periodic
          reviews with all stake holders to stay relevant.
        </p>
      </Container>
    </>
  );
};

export default Governance;
