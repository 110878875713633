import React, {  useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import skillingimg from "../../../Images/OurAchievements/IMG_3630.jpg";
import womenempowermentlogo from "../../../Images/OurAchievements/IMG_3632.jpg";
import livelihoodgeneration from "../../../Images/OurAchievements/IMG_3633.jpg";
import AOS from "aos";

const OurAchievements = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const divStyle = {
    color: "#183b56",
  };
  const strongStyle = {
    color: "#EE7730",
  };

  return (
    <>
      <div className=" main-title ">
        <h2 style={divStyle} className="text-center title-styling">
          OUR ACHIEVEMENTS
        </h2>
        <Container>
          <Row className="d-flex align-items-center justify-content-center">
            <Col className=" mt-md-5" md={6} data-aos="fade-right">
              <Card className="rounded-4 border border-0 p-2">
                <Card.Body>
                  <h2 className="ourAchHeadings title-styling">Skilling</h2>
                  <Card.Text className="text-style">
                    At the core of <strong style={strongStyle}>NDES </strong>
                    lays a profound acknowledgment of the transformative
                    influence that skill acquisition exerts on individuals and
                    communities. Our skilling initiatives are meticulously
                    designed to bridge the divide between aspirations and
                    opportunities. Through the provision of accessible and
                    high-quality education.
                    <strong>
                      We empower individuals to unlock their latent potential,
                      fostering meaningful contributions to society.
                    </strong>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className=" mt-5" md={6} data-aos="fade-left">
              <Card className="rounded-4 border border-0">
                <Card.Body className="ourAchImgs">
                  <img
                    src={skillingimg}
                    alt="Skilling img"
                    className="img-fluid "
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="d-flex align-items-center justify-content-center">
            <Col md={6} data-aos="fade-right">
              <Card className="rounded-4 border border-0">
                <Card.Body>
                  <img
                    src={womenempowermentlogo}
                    alt="women-empowerment-img"
                    className="img-fluid ourAchImgs"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} data-aos="fade-left">
              <Card className="rounded-4 border border-0">
                <Card.Body>
                  <h2 className=" title-styling ourAchHeadings">
                    Women Empowerment
                  </h2>
                  <Card.Text className="text-style">
                    In its commitment to promoting gender equality and
                    empowering women to take on leadership roles, innovate, and
                    drive lasting change,
                    <strong style={strongStyle}>NDES</strong> places a strong
                    emphasis on the
                    <strong> Prevention Of Sexual Harassment (POSH).</strong>
                    Through focused initiatives, we are dedicated to providing
                    women with the necessary tools, resources, and support to
                    overcome obstacles, pursue their aspirations, and emerge as
                    influential leaders within their respective domains.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="d-flex align-items-center justify-content-center">
            <Col className=" mt-5" md={6} data-aos="fade-right">
              <Card className="rounded-4 border border-0">
                <Card.Body>
                  <h2 className=" title-styling ourAchHeadings">
                    Livelihood Generation
                  </h2>
                  <Card.Text className="text-style">
                    Recognizing the crucial role of sustainable livelihoods in
                    fostering community prosperity,
                    <strong style={strongStyle}>NDES</strong> channels its
                    endeavours toward the establishment of economic
                    opportunities, entrepreneurship promotion, and advocacy for
                    environmentally sustainable practices. In addition to this,
                    <strong>
                      we actively engage in initiatives to promote financial
                      inclusion, aiming to empower individuals economically and
                      broaden access to financial resources.
                    </strong>
                    Our overarching objective is to uplift communities by laying
                    the groundwork for long-term prosperity through a holistic
                    approach that integrates entrepreneurship, financial
                    inclusion, and environmentally conscious practices.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className=" mt-5" md={6} data-aos="fade-left">
              <Card className="rounded-4 border border-0">
                <Card.Body>
                  <img
                    src={livelihoodgeneration}
                    alt="livelihood generation img"
                    className="img-fluid ourAchImgs"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default OurAchievements;
