import React, { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import Validation from "../../Validation";
import axios from "axios";
import "../../../CSS/main.css";

const ContactForm = ({ props }) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    category: "",
    message: "",
  });
  const [submissionStatus, setSubmissionStatus] = useState("");

  const handleChange = (e) => {
    const newObj = { ...formData, [e.target.name]: e.target.value };
    setFormData(newObj);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = Validation(formData);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await axios.post(
          "https://ndskills.in/contactForm.php",
          // "https://backend-ndskills.onrender.com/api/contactForm",
          {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            category: formData.category,
            message: formData.message,
          }
        );
        console.log("Form data submitted:", formData);
        console.log(response.data);
        setFormData({
          name: "",
          email: "",
          phone: "",
          category: "",
          message: "",
        });
        setSubmissionStatus("Your form was submitted successfully!");
      } catch (error) {
        console.error("There was an error posting the data!", error);
        setSubmissionStatus(
          "There was an error submitting the form. Please try again."
        );
      }
    } else {
      console.log("Validation errors:", validationErrors);
    }
  };

  return (
    <>
      <Container className="mt-5 bg-light-subtle backGroundColor">
        <Card className="mx-auto  p-md-5 contact-width">
          <Row>
            <Col>
              <h2 className="text-center textColor pb-3">
                Fill the Below Form to Reach Out to Us
              </h2>
            </Col>
          </Row>
          <Container>
            <Form
              onSubmit={handleSubmit}
              className="mx-auto d-flex flex-column gap-3"
            >
              {/* ******************************* Username ******************************** */}
              <Form.Group controlId="formName">
                <Form.Label className="fw-bold">
                  Name<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <p style={{ color: "red", paddingLeft: "5px" }}>
                    {errors.name}
                  </p>
                )}
              </Form.Group>

              {/* ****************************** Email *********************************** */}
              <Form.Group controlId="formEmail">
                <Form.Label className="fw-bold">
                  Email<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <p style={{ color: "red", paddingLeft: "5px" }}>
                    {errors.email}
                  </p>
                )}
              </Form.Group>

              {/* ************************** Phone Number ******************** */}
              <Form.Group controlId="formPhone">
                <Form.Label className="fw-bold">
                  Phone<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                />
                {errors.phone && (
                  <p style={{ color: "red", paddingLeft: "5px" }}>
                    {errors.phone}
                  </p>
                )}
              </Form.Group>

              {/* ************************School****************************** */}
              <Form.Label className="fw-bold">
                {props.name}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                onChange={handleChange}
                name="category"
                value={formData.category}
                aria-label="Default select example"
              >
                <option value="">{props.option1}</option>
                <option value={props.option2}>{props.option2}</option>
                <option value={props.option3}>{props.option3}</option>
                <option value={props.option4}>{props.option4}</option>
              </Form.Select>

              {/* ********************************** message ************************/}
              <Form.Group controlId="formMessage" className="mt-3">
                <Form.Label className="fw-bold">Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Enter your message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </Form.Group>

              {/* ********************************* submit button ********************************** */}
              <button
                type="submit"
                className="btn1 border-0 px-3 py-2 mt-3 rounded-1 fs-6"
              >
                Submit
              </button>
              {submissionStatus && (
                <p
                  style={{
                    color: submissionStatus.includes("successfully")
                      ? "green"
                      : "red",
                    marginTop: "10px",
                  }}
                >
                  {submissionStatus}
                </p>
              )}
            </Form>
          </Container>
        </Card>
      </Container>
    </>
  );
};

export default ContactForm;
