import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Statistic } from "antd";
import CountUp from "react-countup";
import AOS from "aos";
import "../../CSS/About.css";
import "../../CSS/main.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import founderPic from "../../Images/Aboutus-pics/IMG_3225.jpg";
import ceoPic from "../../Images/Aboutus-pics/CEO-PIC.jpg";
import award1 from "../../Images/Aboutus-pics/women0f2023.jpg";
import award3 from "../../Images/Gallery/img-felicitation.jpg";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { PiStudentFill } from "react-icons/pi";
import { IoIosPeople } from "react-icons/io";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaTrophy } from "react-icons/fa";
import Shimmer from "../Common/ShimmerUI/Shimmer";
import { Blurhash } from "react-blurhash";
import { ScrollToTop } from "../Pages/Home/utils";
import MetaTags from "../MetaInfo/MetaTags";

const About = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imgLoading, setImgLoading] = useState({
    ceoPic: false,
    award1: false,
    award3: false,
    founderPic: false,
  });

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  useEffect(() => {
    const images = [ceoPic, award1, award3, founderPic];
    images.forEach((src, index) => {
      const img = new Image();
      img.onload = () => {
        setImgLoading((prev) => ({
          ...prev,
          [Object.keys(imgLoading)[index]]: true,
        }));
      };
      img.src = src;
    });
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const formatter = (value) => (
    <div>
      <CountUp end={value} separator="," />
      <sup> +</sup>
    </div>
  );
  const [ref, inView] = useInView({
    threshold: 0.1,
  });

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const handleOtherToggle = () => {
    setIsCollapse(!isCollapse);
  };
  return (
    <>
      <MetaTags
        title={"Skill Training for Unemployed Youth in Telangana | DDU-GKY"}
        Description={
          "Skill training for unemployed youth in Telangana under DDU-GKY. Boost your career at ND Skill .learn and enhance your skills force livelihood "
        }
        Keywords={
          "Skill Training for Unemployed Youth Telangana   DDU-GKY Skill Training   ND Skills Unemployed Youth Programs    Unemployed Youth Skill Development"
        }
        href={"https://ndskills.in/About"}
        OgTitle={"Skill Training for Unemployed Youth in Telangana | DDU-GKY"}
        OgUrl={"https://ndskills.in/About"}
        OgDescription={
          "Skill training for unemployed youth in Telangana under DDU-GKY. Boost your career at ND Skill .learn and enhance your skills force livelihood"
        }
        OgImg={
          "https://ndskills.in/static/media/img-felicitation.8d9a8f3a550473eebc86.jpg"
        }
        twitterImage={
          "https://ndskills.in/static/media/img-felicitation.8d9a8f3a550473eebc86.jp"
        }
        twitterTitle={
          "Skill Training for Unemployed Youth in Telangana | DDU-GKY"
        }
        twitterDescription={
          "Skill training for unemployed youth in Telangana under DDU-GKY. Boost your career at ND Skill .learn and enhance your skills force livelihood"
        }
      />
      {loading ? (
        <Shimmer />
      ) : (
        <div>
          <div className="container-fluid bg-body-tertiary mt-4 about-div">
            <Container>
              <Row>
                <Col xs={12}>
                  <h1 className="about-heading text-center">
                    NEW DIRECTIONAL EDUCATIONAL SOCIETY (NDES) WE EMPOWER LIVES
                  </h1>
                  <p className="pb-2 text-justify">
                    Our mission is to guide students, underprivileged
                    individuals, and unemployed youth towards new directions and
                    opportunities. Founded by Shri Dasarath Ram Beegala, New
                    Directions Educational Society (NDES) is dedicated to
                    providing international-quality education and training to
                    enhance the employability of Indian youth, enabling them to
                    secure gainful employment or succeed in self-employment.
                  </p>
                  <p className="pb-2 text-justify">
                    From 1999 to 2012, Shri Dasarath Ram represented over 45
                    renowned UK universities, gaining extensive experience as an
                    education counselor and recruiter. During this time, he
                    encountered numerous young individuals, both urban and
                    rural, who possessed the necessary academic credentials but
                    were hindered by a lack of essential skills and
                    communication abilities. This experience inspired the
                    creation of NDES, aimed at empowering these unemployed,
                    unskilled youth.
                  </p>
                  <p className="pb-2 text-justify">
                    Shri Dasarath Ram Beegala, a visionary founder and
                    accomplished advocate with the High Court of Telangana, is a
                    highly skilled social entrepreneur deeply committed to
                    empowering young people and fostering positive societal
                    change. With more than two decades of experience in the
                    legal profession, he understands the challenges facing
                    India’s youth and is determined to address these issues
                    through education and skill development.
                  </p>
                  <p className="pb-2 text-justify">
                    Driven by his passion for education and social justice, Shri
                    Dasarath Ram Beegala established New Directions Skills to
                    provide high-quality training and education to unemployed
                    youth in India. His belief in nurturing skill sets has
                    transformed many young individuals into employable and
                    successful members of society. Under his leadership, New
                    Directions – Skills has become a leading institution in
                    employability training, helping thousands of young people
                    across India achieve their career aspirations and improve
                    their futures.
                  </p>

                  <p className="pb-2 text-justify">
                    Shri Dasarath Ram Beegala’s commitment to social justice and
                    equality extends beyond New Directions – Skills. He actively
                    engages in advocacy work and various community
                    organizations, serving as an advisor to many corporate
                    brands and earning respect in the legal and business
                    communities. His dedication and passion for empowering young
                    people have significantly impacted many lives, and his
                    vision and leadership continue to drive New Directions –
                    Skills forward as a force for positive change in India.
                  </p>
                </Col>
              </Row>

              {/* ************************************** founder-ceo details ***************************************** */}
              <Row className="mt-5">
                <Col
                  xs={12}
                  md={6}
                  data-aos="fade-right"
                  className="d-flex flex-column justify-content-center align-items-center text-center overflow-hidden"
                >
                  <h2 className="founder-name text-style">
                    Smt Samyuktha Beegala
                  </h2>
                  {!imgLoading.ceoPic && (
                    <Blurhash
                      hash="m3Duuf?b008whzs9%gx]0056_3~V*0xFR4570f%2^PIo?uXSRjwI"
                      height={500}
                      width={500}
                      punch={1}
                      className="founder-pic-styling"
                    />
                  )}
                  {imgLoading.ceoPic && (
                    <img
                      src={ceoPic}
                      alt="ceo-pic"
                      className="founder-pic-styling"
                    />
                  )}
                  <h3 className="fw-bold text-style pt-4">CO-FOUNDER</h3>
                </Col>
                <Col
                  xs={12}
                  md={6}
                  data-aos="fade-left"
                  className="d-flex flex-column justify-content-center overflow-hidden align-items-center text-center"
                >
                  <h2 className="founder-name text-style">
                    Shri Dasarath Ram Beegala
                  </h2>
                  {!imgLoading.founderPic && (
                    <Blurhash
                      hash="mADIOMW;000Kvz9tx]~BIA$*%fS$5;xtvzMxEMsn$%J9I:j[%1jZ"
                      height={500}
                      width={500}
                      punch={1}
                      className="founder-pic-styling"
                    />
                  )}
                  {imgLoading.founderPic && (
                    <img
                      src={founderPic}
                      alt="founder-pic"
                      className="founder-pic-styling"
                    />
                  )}
                  <h3 className="fw-bold text-style pt-4">FOUNDER</h3>
                </Col>
              </Row>

              {/* ***************************** Accomplishment **************************/}

              <Row className=" mt-5 lh-lg  py-4">
                <h2 className="text-center textColor pb-3 fs-1">
                  Accomplishments
                </h2>
                <Col xs={12} md={6} xl={8} data-aos="fade-right">
                  <p>
                    New Directions Education and Welfare Society, Hyderabad,
                    Regd. No. 393/2010, is a distinguished organization
                    registered under the Societies Act of 1860. We hold 12A and
                    80G registrations, making us an Income Tax-exempt NGO
                    dedicated to Women Empowerment through Skill Development,
                    Entrepreneurship Training, and Incubation of enterprises.
                    <span
                      style={{ display: isExpanded ? "none" : "inline" }}
                      id="dots"
                    >
                      ...
                    </span>
                    <span
                      style={{ display: isExpanded ? "inline" : "none" }}
                      id="more"
                    >
                      {" "}
                      Committed to providing a sustainable way of life for
                      underprivileged and disadvantaged women, we operate under
                      the umbrella of NGO Darpan with a UNIQUE ID: TS/
                      2018/0197015. Additionally, we are recognized by the
                      Ministry of Corporate Affairs, GOI, as a CSR Implementing
                      Organization, with Regd. No.: CSR00005204. Smt. Samyuktha
                      Beegala, the co-founder and CEO of ND Skills, a unit of
                      M/s. New Directions and Welfare Society, has led our
                      organization in empowering countless students over the
                      past decade. Our focus on livelihood-based education and
                      skills development aims to nurture their chosen careers
                      for better living and social development.
                    </span>
                  </p>
                  <span
                    className="border border-0 text-danger rounded-1 fs-6 fw-medium btn-space"
                    onClick={handleToggle}
                  >
                    {isExpanded ? "Read less" : "Read more..."}
                  </span>
                </Col>
                <Col data-aos="fade-left" xs={12} md={6} xl={4}>
                  {!imgLoading.award1 && (
                    <Blurhash
                      hash="m597CH5EEN},s=xo9b-UNI%KxuIUxS4?%2t2E3xAM}t7s;WBNGt7"
                      height={250}
                      width={400}
                      punch={1}
                      className="rounded img-fluid"
                    />
                  )}
                  {imgLoading.award1 && (
                    <img
                      src={award1}
                      alt="awardpic"
                      className=" rounded img-fluid"
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col
                  data-aos="fade-right"
                  xs={12}
                  md={6}
                  xl={4}
                  className="img-distance"
                >
                  {" "}
                  {!imgLoading.award3 && (
                    <Blurhash
                      hash="jiMQI*_NodtRXTxu"
                      height={250}
                      width={350}
                      punch={1}
                      className="img-distance"
                    />
                  )}
                  {imgLoading.award3 && (
                    <img
                      src={award3}
                      alt="awardpic"
                      className="rounded img-fluid "
                    />
                  )}
                </Col>
                <Col
                  xs={12}
                  md={6}
                  xl={8}
                  className="lh-lg "
                  data-aos="fade-left"
                >
                  {" "}
                  <p>
                    {" "}
                    Operating in diverse locations such as Telangana
                    (Hyderabad), Andhra Pradesh (Visakhapatnam, Kakinada),
                    Kerala (Cochin), and internationally in Australia
                    (Melbourne) and the United States of America (Boston), we
                    have a global footprint in our pursuit of social impact.
                    <span
                      style={{ display: isCollapse ? "none" : "inline" }}
                      id="dots"
                    >
                      ...
                    </span>
                    <span
                      style={{ display: isCollapse ? "inline" : "none" }}
                      id="more"
                    >
                      In addition to our core initiatives, we are actively
                      involved in migration and healthcare businesses, further
                      expanding our commitment to holistic community
                      development. This multifaceted approach aligns with our
                      mission to bring positive change and sustainable
                      livelihoods to the communities we serve. We are honored to
                      have received the Award of Excellence in April 2023 from
                      the Media and Entertainment Skills Council, recognizing
                      our outstanding contributions to the field.
                    </span>
                  </p>
                  <span
                    className="border border-0 text-danger rounded-1 fs-6 fw-medium btn-space"
                    onClick={handleOtherToggle}
                  >
                    {isCollapse ? "Read less" : "Read more..."}
                  </span>
                </Col>
              </Row>

              {/* *************************** Our Contribution *********************************** */}

              <Row>
                <Col xs={12}>
                  <h2
                    className="text-center textColor fs-1"
                    data-aos="fade-up"
                    ref={ref}
                  >
                    Our Contribution
                  </h2>
                </Col>
              </Row>
              <Row className="ms-5">
                <Col
                  xs={12}
                  sm={6}
                  lg={3}
                  data-aos="fade-up"
                  className="d-flex gap-4 align-items-center"
                >
                  <FaPeopleGroup size={40} />
                  <Statistic
                    title="Students Trained"
                    value={inView ? 1000 : 0}
                    formatter={formatter}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={3}
                  data-aos="fade-up"
                  className="mb-md-3 d-flex gap-4 align-items-center"
                >
                  <PiStudentFill size={40} />
                  <Statistic
                    title="Batches"
                    value={inView ? 200 : 0}
                    formatter={formatter}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={3}
                  data-aos="fade-up"
                  className="d-flex gap-4 align-items-center"
                >
                  <IoIosPeople size={40} />
                  <Statistic
                    title="Trainees"
                    value={inView ? 6000 : 0}
                    formatter={formatter}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={3}
                  data-aos="fade-up"
                  className="mb-md-3 d-flex gap-4 align-items-center"
                >
                  <FaTrophy size={35} />
                  <Statistic
                    title="Success Stories"
                    value={inView ? 1000 : 0}
                    formatter={formatter}
                  />
                </Col>
              </Row>

              {/* *********************************** our orgainsation **************************************************** */}
              <Row>
                <Col xs={12}>
                  <h2 className="textColor">OUR ORGANIZATION</h2>
                  <p className="pb-2 text-justify">
                    New Directions Educational Society (NDES) is a wholly
                    apolitical, non-profit, secular, social development
                    organization. NDES focuses on Skill Development, Training,
                    and Placement Help for both educated and illiterate
                    unemployed youth. We at NDES are convinced that only the
                    proper education and empowerment of the youth can alter the
                    nation’s future. Only employed/economically productive
                    adolescents will become future responsible citizens and
                    contribute to the process of nation building.
                  </p>
                  <p className="pb-2 text-justify">
                    Our strategy seeks to achieve comprehensive socioeconomic
                    development and the economic development of the nation with
                    a focus on the underprivileged by working in the following
                    functional areas: education, empowerment, and gender
                    equality.
                  </p>
                  <p className="pb-2 text-justify">
                    NDES functions as a catalyst by bridging the gap between the
                    industry’s needs and the market’s supply, and we achieve
                    this by building the potential capabilities of these aimless
                    kids and preparing them to demonstrate their talents on par
                    with those of the qualified experts accessible on the
                    market.
                  </p>
                  <p className="pb-2 text-justify">
                    Education for the underprivileged, Skill Development
                    programs for youth, and the Empowerment of deserving youth
                    and women are the focal points of the NDES’s activity. We
                    feel that in the current environment of living in a global
                    village, any effort that is not integrated will be
                    ineffective. We collaborate with numerous stakeholders,
                    whose contributions make this endeavor more effective,
                    responsive, and sustainable.
                  </p>
                  <p className="pb-2 text-justify">
                    We work directly or indirectly with various communities,
                    governments, corporate societies, and civil societies by
                    utilizing the available resources, i.e., human capital,
                    network, finances, etc., in a constructive and collaborative
                    manner so as to achieve the goal of reforming the deprived
                    communities as a whole.
                  </p>
                </Col>

                {/* **************************************** our goals and objectives ******************************************* */}

                <Col xs={12}>
                  <h2 className="textColor">Our Goals & Objectives</h2>
                  <p className="pb-4 text-justify">
                    Our goals and objectives are to provide high-quality
                    education and training to students and unemployed youth,
                    both in India and overseas, equipping them with the skills
                    needed by the industry. We aim to eradicate and prevent
                    child labor, facilitate capacity-building programs for
                    unemployed youth in both rural and urban areas, and foster
                    positive qualitative changes in the lives of marginalized
                    communities.
                  </p>
                </Col>
              </Row>
            </Container>

            {/* ****************************************** join us today ******************************************************** */}
          </div>
          <div className="text-center w-75 d-flex flex-column mx-auto">
            <h2 className="pt-5 pb-3 textColor">JOIN US TODAY!</h2>
            <div className="d-flex flex-column gap-3 align-items-center fw-bolder">
              <h6 className="join-us-styling">
                TOGETHER, WE CAN FORGE A BRIGHTER FUTURE FOR ALL
              </h6>
              <h6 className="join-us-styling">
                At New Directions Educational Society (NDES), we believe in
                transforming potential into proficiency, guiding each individual
                towards success and self-discovery.
              </h6>

              <h6 className="join-us-styling">
                JOIN US IN THIS JOURNEY OF EMPOWERMENT, EDUCATION, AND
                SUSTAINABLE DEVELOPMENT.
              </h6>
              <button className="border border-0 rounded-1 fs-6 fw-medium px-4 py-2 btn1">
                <Link
                  to="/Contactus"
                  className="text-decoration-none text-white"
                  onClick={ScrollToTop}
                >
                  Contact Us
                </Link>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default About;
