import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import paidprogramslogo from "../../../Images/OurCurrentProgrammes/paid_programes_nd_skills_website_images2-png.png";
import Gvtlogo from "../../../Images/OurCurrentProgrammes/MSDE-Eng-logo-removebg-preview.png";
import csrlogo from "../../../Images/OurCurrentProgrammes/csr final website image-01.jpg";
import "../../../CSS/Home.css";
import "../../../CSS/main.css";
import { ScrollToTop } from "../Home/utils";
import { Blurhash } from "react-blurhash";
import newImg from "../../../Images/Common/new-img-updates.gif";
import { ContextStore } from "../../../App";
const OurCurrentPrograms = () => {
  const [imgLoading, setImgLoading] = useState({
    Gvtlogo: false,
    csrlogo: false,
    paidprogramslogo: false,
  });

  const { isVisible, setIsVisible } = useContext(ContextStore);

  const divStyle = {
    color: "#183b56",
  };
  useEffect(() => {
    const images = [Gvtlogo, paidprogramslogo, csrlogo];
    images.forEach((src, index) => {
      const img = new Image();
      img.onload = () => {
        setImgLoading((prev) => ({
          ...prev,
          [Object.keys(imgLoading)[index]]: true,
        }));
      };
      img.src = src;
    });
  });

  return (
    <>
      <div className="container-fluid bg-body-tertiary text-center my-5">
        <h3 style={divStyle} className="title-styling pt-5 pb-3 fw-medium">
          Our Current Programs
        </h3>
        <Container className="text-start">
          <Row>
            <Col md={4} className="accordion-col">
              <Card>
                {!imgLoading.Gvtlogo && (
                  <Blurhash
                    hash="mJOM~djY~qoz-;ofxuaySikCRPaKx[WVRjofxaf6bckCRjj[f6WB"
                    height={500}
                    width={500}
                    punch={1}
                    className="img-fluid Gvt-Logo "
                  />
                )}
                {imgLoading.Gvtlogo && (
                  <img
                    src={Gvtlogo}
                    alt="Gvt Logo"
                    className="img-fluid Gvt-Logo "
                  />
                )}
                <Accordion className="rounded ">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h5 className="px-4">
                        {" "}
                        Government Programs
                        <sup>
                          <img src={newImg} alt="new-img" className="new-img" />
                        </sup>
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body className="px-5 accordion-body">
                      <h6 className="fw-bold">1.DDUGKY MoRD – GOI</h6>
                      <p>
                        <strong>Program :</strong>Search Engine Optimisation
                        Executive (SEO )
                      </p>
                      <p>Training</p>
                      <p>QP Code :MES/Q0704</p>
                      <p>
                        <strong>Status :</strong> Ongoing
                      </p>

                      <p>
                        <strong>Program Location</strong>: HYDERABAD
                      </p>
                      <button
                        onClick={() => setIsVisible(true)}
                        className="border border-0 fs-6 fw-medium px-4 py-2 mb-3
                      btn1"
                      >
                        Apply Now
                      </button>
                      <h6 className="fw-bold">
                        2.SKILL DEVELOPMENT IN ELECTRONICS SYSTEM DESIGN AND
                        MANUFACTURING (ESDM)
                      </h6>
                      <p>BATCHES UNDERTAKEN IN 2023-24</p>
                      <p>Solar Panel Installation Technician : 180 Nos</p>
                      <p>Multi Skill Technician : 60 Nos</p>
                      <strong>Total : 240 No’s</strong>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Card>
            </Col>
            <Col md={4} className="accordion-col">
              <Card>
                {!imgLoading.csrlogo && (
                  <Blurhash
                    hash="m8R{.7%2~px[%MkBWBRj_3kBIpRk-;xtV@WBRjM{-:V@NHWC%2of"
                    height={500}
                    width={500}
                    punch={1}
                    className="img-fluid "
                  />
                )}
                {imgLoading.csrlogo && (
                  <img src={csrlogo} alt="CSR logo" className="img-fluid" />
                )}
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h5 className="px-4">CSR Programs</h5>
                    </Accordion.Header>
                    <Accordion.Body className="px-5 accordion-body">
                      <p>
                        Programs Offered in Association with{" "}
                        <strong>• CGF & SAMHITA</strong>
                      </p>
                      <p>
                        <strong>Program:</strong> Accenture
                      </p>
                      <p>
                        <strong>Course:</strong> Solar PV Installation
                        Techincian
                      </p>
                      <p>
                        <strong>QP Code:</strong>SGJ/Q0101
                      </p>
                      <p>
                        <strong>Status:</strong> Batch started on 8th July
                      </p>
                      <p>
                        <strong>Total : 100 No’s</strong>
                      </p>

                      <p>
                        <strong>Program:</strong> Solar PV Installation
                        Technician.
                      </p>
                      <p>
                        <strong>Status:</strong> Completed
                      </p>
                      <p>ACCENTURE Funding Support Received -180 No’s</p>
                      <p>SIGNODE Funding Support Received -30 No’s </p>
                      <strong>Total : 210 No’s</strong>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Card>
            </Col>
            <Col md={4} className="accordion-col">
              <Card>
                {!imgLoading.paidprogramslogo && (
                  <Blurhash
                    hash="oKODd@jZ?bkDs,n$%Mj[ofazayoL~qkCDijFW?bbxua|M{oLs:R*M_ayxvj[RiaeM{j[xuWBa#t7"
                    height={500}
                    width={500}
                    punch={1}
                    className="img-fluid "
                  />
                )}
                {imgLoading.paidprogramslogo && (
                  <img
                    className="img-fluid"
                    src={paidprogramslogo}
                    alt="Paid Programs Img"
                  />
                )}
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h5 className="px-4">Paid Programs</h5>
                    </Accordion.Header>
                    <Accordion.Body className="px-5 accordion-body">
                      <p>
                        <strong>Program:</strong> PG Diploma in digital
                        marketing and analytics.
                      </p>
                      <p>
                        <strong>Programs details:</strong>
                      </p>
                      <p>
                        11 months Full time course + 100% placement Training.
                      </p>
                      <p>Admissions open from April 2024</p>
                      <p>
                        <strong>Status :</strong> Coming Soon
                      </p>

                      <p>
                        <strong>IELTS Online Training Program</strong>
                      </p>
                      <p>
                        <strong>Status:</strong> Batches starts 1st of every
                        month.
                      </p>
                      <button className="border-1 btn1 btn2 py-2 fs-5">
                        <Link
                          to="/Contactus"
                          className="text-decoration-none text-white"
                          onClick={ScrollToTop}
                        >
                          Enroll Now
                        </Link>
                      </button>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default OurCurrentPrograms;
