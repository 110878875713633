import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../CSS/Schools.css";

const ClickHere = ({ details }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 576);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 576);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openPdf = (pdf) => {
    window.open(pdf, "_blank");
  };

  return (
    <div className="d-flex flex-column gap-sm-4 gap-md-0">
      <Row className="text-center mt-5">
        {details.map((detail, index) => (
          <Col key={index} xs={12} lg={6}>
            <h3 className="textColor  acit-style">{detail.name}</h3>
            <button
              className={`${
                isSmallScreen
                  ? "border border-0 mb-3 rounded-1 p-2 fs-6 fw-medium btn1"
                  : "border border-0 mb-3 rounded-1 p-3 fs-5 fw-medium btn1"
              }`}
              onClick={() => openPdf(detail.prop)}
            >
              {isSmallScreen
                ? "Click Here"
                : "Click Here View The Course Details"}
            </button>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ClickHere;
