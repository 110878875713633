import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { images, trainingImages, assessmentImages } from "./Home/utils";
import "../../CSS/main.css";
import "../../CSS/gallery.css";
import Shimmer from "../Common/ShimmerUI/Shimmer";
import { Blurhash } from "react-blurhash";
import MetaTags from "../MetaInfo/MetaTags";

const Gallery = () => {
  const [loading, setLoading] = useState(true);
  const [imgLoadingStates, setImgLoadingStates] = useState({});
  const [trainingImgLoadingStates, setTrainingImgLoadingStates] = useState({});
  const [assessmentImgLoadingStates, setAssessmentImgLoadingStates] = useState(
    {}
  );
  const [modalShow, setModalShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const handleImageLoad = (index, type) => {
    switch (type) {
      case "images":
        setImgLoadingStates((prevState) => ({
          ...prevState,
          [index]: true,
        }));
        break;
      case "trainingImages":
        setTrainingImgLoadingStates((prevState) => ({
          ...prevState,
          [index]: true,
        }));
        break;
      case "assessmentImages":
        setAssessmentImgLoadingStates((prevState) => ({
          ...prevState,
          [index]: true,
        }));
        break;
      default:
        break;
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalShow(true);
  };

  const renderImages = (imagesArray, loadingStates, type) => (
    <Row className="justify-content-center">
      {imagesArray.map((image, index) => (
        <Col key={index} xs={12} sm={6} md={4} className="mb-3">
          <div className="image-container fadein">
            {!loadingStates[index] && (
              <Blurhash
                hash="jiMQI*_NodtRXTxu"
                height={400}
                width={357}
                punch={1}
                className="img-fluid"
              />
            )}
            <img
              src={image.url}
              alt={image.alt}
              style={{
                width: "100%",
                height: "400px",
                objectFit: "cover",
                display: loadingStates[index] ? "block" : "none",
              }}
              className={`img-fluid ${loadingStates[index] ? "" : "d-none"}`}
              onLoad={() => handleImageLoad(index, type)}
              onClick={() => handleImageClick(image.url)}
            />
          </div>
        </Col>
      ))}
    </Row>
  );

  return (
    <>
      <MetaTags
        title={"ND Skills Gallery - Explore Our Training Program Highlights"}
        Description={
          "Browse the ND Skills Gallery to see highlights from our training programs, student achievements, and campus events. Discover our vibrant learning community!"
        }
        Keywords={"ND Skills Gallery"}
        href={"https://ndskills.in/Gallery"}
        OgTitle={"ND Skills Gallery - Explore Our Training Program Highlights"}
        OgUrl={"https://ndskills.in/Gallery"}
        OgDescription={
          "Browse the ND Skills Gallery to see highlights from our training programs, student achievements, and campus events. Discover our vibrant learning community!"
        }
        OgImg={"https://ndskills.in/static/media/sir.4312976406b1a29d5c47.jpg"}
        twitterImage={
          "https://ndskills.in/static/media/mam.ee4cc6318ed292105aec.jpg"
        }
        twitterTitle={
          "ND Skills Gallery - Explore Our Training Program Highlights"
        }
        twitterDescription={
          "Browse the ND Skills Gallery to see highlights from our training programs, student achievements, and campus events. Discover our vibrant learning community!"
        }
      />
      {loading ? (
        <Shimmer />
      ) : (
        <Container>
          <Row>
            <h1 className="text-center my-4 textColor">Gallery</h1>
          </Row>
          {renderImages(images, imgLoadingStates, "images")}
          <Row>
            <h3 className="text-center">Training</h3>
          </Row>
          {renderImages(
            trainingImages,
            trainingImgLoadingStates,
            "trainingImages"
          )}
          <Row>
            <h3 className="text-center">Assessment</h3>
          </Row>
          {renderImages(
            assessmentImages,
            assessmentImgLoadingStates,
            "assessmentImages"
          )}
        </Container>
      )}

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ width: "full", height: "auto" }}>
          {selectedImage && (
            <img src={selectedImage} className="w-100 h-100" alt="Full Size" />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Gallery;
