import React, { useEffect, useState } from "react";
import SolarPanel from "../../../Images/Schools&Programmes/Solar-Plant-Installation.jpg";
import "../../../CSS/main.css";
import { Row, Col } from "react-bootstrap";
import ContactForm from "./ContactForm";
import pdf1 from "../../../Assets/CCTV-Installation-Tecnical.pdf";
import pdf2 from "../../../Assets/ELEQ3109_Multi-skill-technician1.pdf";
import pdf3 from "../../../Assets/ELEQ4608_Access_Controls.pdf";
import pdf4 from "../../../Assets/QP_ELE-Q5901_Solar-Panel.pdf";
import ClickHere from "./clickHere";
import "../../../CSS/Schools.css";
import Shimmer from "../../Common/ShimmerUI/Shimmer";
import { Blurhash } from "react-blurhash";
import MetaTags from "../../MetaInfo/MetaTags";

const SchoolOfBuildingAndAutomation = () => {
  const [loading, setLoading] = useState(true);
  const [imgLoading, setImgLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImgLoading(true);
    };
    img.src = SolarPanel;
  }, []);

  const details = [
    { name: "Multi Skill Technician", prop: { pdf1 } },
    { name: "CCTV Installation Technician", prop: { pdf2 } },
    { name: "Access Controls Installation Technician", prop: { pdf3 } },
    { name: "Solar-Panel Installation Technician", prop: { pdf4 } },
  ];

  return (
    <>
      <MetaTags
        title={"Master Building & Automation Skills in Telangana | ND Skills "}
        Description={
          "Join ND Skills' Building & Automation Program in Telangana. Get hands-on training and certifications to boost your career."
        }
        Keywords={
          "Building & Automation Skills Training Telangana Automation skills development telangana courses Automation Testing Courses in Hyderabad NDSkills Building Automation Program"
        }
        href={"https://ndskills.in/School-of-building-automation"}
        OgTitle={"Master Building & Automation Skills in Telangana | ND Skills"}
        OgUrl={"https://ndskills.in/School-of-building-automation"}
        OgDescription={
          "Join ND Skills' Building & Automation Program in Telangana. Get hands-on training and certifications to boost your career."
        }
        OgImg={
          "https://ndskills.in/static/media/Solar-Plant-Installation.075a8ccfd14f42bee3ff.jpg"
        }
        twitterImage={
          "https://ndskills.in/static/media/Skills_Nd_Skills_website_image-01-removebg-preview.3881a10a60f31d0e3dfa.png"
        }
        twitterTitle={
          "Skill Development Courses in Telangana -New Directions Skills"
        }
        twitterDescription={
          "Discover top skill development courses in Telangana at ND Skills. Enhance your career with DDU GKY-certified programs. Boost your employability today!"
        }
      />
      {loading ? (
        <Shimmer />
      ) : (
        <div className="container-fluid bg-body-tertiary pt-4">
          <Row className="py-5 px-sm-3">
            <Col
              md={6}
              className="d-flex flex-column align-items-start justify-content-center "
            >
              <h1 className="textColor fs-3 pb-2">
                School of Building and Automation
              </h1>
              <p className="lh-lg">
                Possessing the appropriate skills in building and automation is
                akin to possessing a superpower for comprehending and
                orchestrating the intricacies of construction processes. It
                involves becoming adept at navigating architectural blueprints,
                utilizing sophisticated automation tools, and unravelling the
                significance of each component. Picture yourself as the
                architect who can decode the hidden patterns within the
                construction landscape! If you have a passion for designing,
                love the hands-on experience of building, and find joy in
                unravelling the complexities of structural systems, then
                building and automation skills are your key. It’s like having
                your own set of tools to unlock the mysteries concealed within
                the realm of construction, allowing you to shape and innovate in
                the world of structures.
              </p>
            </Col>
            <Col md={6}>
              {!imgLoading && (
                <Blurhash
                  hash="]QGR;vWAM_NfIU~TjGR*ofRj?an#ozt7bHt8Ios,o#smxaRjoLt7aekXe.WWsmRjafbbV@RkoLM{ofoeR+s:M{jFW=oLof"
                  height={500}
                  width={600}
                  punch={1}
                  className="img-fluid object-fit-cover rounded img-responsiveness"
                />
              )}
              {imgLoading && (
                <img
                  src={SolarPanel}
                  alt="SolarPanel"
                  className="img-fluid object-fit-cover rounded img-responsiveness"
                />
              )}
            </Col>
          </Row>
        </div>
      )}

      <ClickHere details={details} />

      <ContactForm
        props={{
          name: "School of Building and Automation",
          option1: "CCTV Installation Technician",
          option2: "Solar Panel Installation Technician",
          option3: "Access Control Installation Technician",
          option4: "Multi Skill Technician",
        }}
      />
    </>
  );
};

export default SchoolOfBuildingAndAutomation;
