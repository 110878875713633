import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { videosArray } from "./utils";

const VideoTestimonials = () => {
  const divStyle = {
    color: "#183b56",
  };

  const videoStyle = {
    width: "100%",
    height: "200px",
    objectFit: "cover",
  };

  return (
    <Container>
      <Row>
        <h2 className="text-center my-5 title-styling" style={divStyle}>
          Video Testimonials
        </h2>
      </Row>
      <Row>
        {videosArray.map((video) => (
          <Col key={video.id} xs={12} sm={6} md={4} className="mb-4">
            <video controls style={videoStyle}>
              <source src={video.url} type="video/mp4" />
            </video>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default VideoTestimonials;
