import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

import Mission from "../../../Images/OurCurrentProgrammes/rocket_599193.png";
import Vision from "../../../Images/OurCurrentProgrammes/telescope_855564.png";
import corevalues from "../../../Images/OurCurrentProgrammes/impact_11152600.png";

const MissionVisionValues = () => {
  const divStyle = {
    color: "#183b56",
  };
  const strongStyle = {
    color: "#EE7730",
  };
  return (
    <>
      <div className="container-fluid " id="home-cards">
        <Container>
          <Row className="">
            <Col className="mt-5 vision-card" md={6} data-aos="zoom-in">
              <Card className="rounded-4 h-100">
                <Card.Body className="parent vision-content ">
                  <img src={Vision} alt="Vision img" id="vision-img" />

                  <h2 className="text-center title-styling" style={divStyle}>
                    Vision
                  </h2>
                  <Card.Text className="text-style">
                    The vision of <strong style={strongStyle}>NDES</strong> is
                    centred on cultivating a world where every individual is
                    afforded the opportunity to realize their full potential,
                    irrespective of background or circumstance. We envisage a
                    society wherein education, empowerment, and sustainable
                    practices form the bedrock of progress and well-being.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="mi-vi-cards mt-5" md={6} data-aos="zoom-in">
              <Card className="rounded-4 h-100">
                <Card.Body className="parent">
                  <img src={Mission} alt="Mission img" id="Mission-img" />

                  <h2 style={divStyle} className="title-styling">
                    Mission
                  </h2>
                  <Card.Text className="text-style pb-3">
                    <strong style={strongStyle}>NDES</strong> embarks on a
                    mission to transform lives by providing access to quality
                    education, promoting women’s empowerment, and fostering
                    sustainable livelihoods. Our dedication is reflected in the
                    positive impact we strive to make on communities, the
                    cultivation of a culture of learning, and the establishment
                    of bridges towards a more equitable and inclusive world.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col className="my-5" md={12} data-aos="zoom-in">
              <Card className="rounded-4">
                <Card.Body className="parent">
                  <img
                    src={corevalues}
                    alt="corevalues img"
                    id="corevalues-img"
                  />

                  <h2 className="text-center title-styling" style={divStyle}>
                    CORE VALUES
                  </h2>
                  <Card.Text className="text-style ">
                    <strong style={strongStyle}> Empathy:</strong> Guided by a
                    deep understanding of the diverse needs and aspirations of
                    the communities we serve.
                    <br />
                    <br />
                    <strong style={strongStyle}>Integrity:</strong> Our actions
                    are steered by principles of honesty, transparency, and
                    unwavering commitment to ethical practices.
                    <br />
                    <br />
                    <strong style={strongStyle}>Collaboration:</strong> We
                    recognize and actively seek the strength in partnerships,
                    maximizing our impact through collaborative endeavour’s.
                    <br />
                    <br />
                    <strong style={strongStyle}>Innovation:</strong> Embracing
                    creativity and innovation as instrumental forces for driving
                    positive change.
                    <br />
                    <br />
                    <strong style={strongStyle}>Sustainability:</strong> Our
                    commitment to sustainability is manifested through efforts
                    aimed at creating lasting and meaningful impact.
                    <br />
                    <br />
                    Together, we can forge a brighter future for all. At{" "}
                    <strong style={strongStyle}>
                      New Directions Educational Society (NDES),
                    </strong>
                    we believe in transforming potential into proficiency,
                    guiding each individual towards success and self-discovery.
                    <br />
                    <br />
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MissionVisionValues;
