import React, { useState, useEffect, useContext, Suspense, lazy } from "react";
import SeoImg from "../../Images/Common/seo-img.webp";
import { IoClose } from "react-icons/io5";
// import { Blurhash } from "react-blurhash";

import "../../CSS/pop.css"; // Ensure your CSS file contains necessary styles
import { Link } from "react-router-dom";
import { ContextStore } from "../../App";
import { ScrollToTop } from "../Pages/Home/utils";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ImageComponent from "./ImageComponent";
// import Image from "react-bootstrap/Image";

// Lazy load the Image component
const Image = lazy(() => import("react-bootstrap/Image"));

const Popup = () => {
  const { isVisible, setIsVisible, isImgLoaded } = useContext(ContextStore);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, [setIsVisible]);

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <Modal show={isVisible} onHide={handleClose} centered>
      {
        <Modal.Body className="d-flex flex-column align-items-center">
          {isImgLoaded && (
            <Button variant="link" className="ms-auto" onClick={handleClose}>
              <IoClose size={24} />
            </Button>
          )}
          {/* <Suspense fallback={<div>Loading...</div>}>
          <Image
            src={SeoImg}
            loading="lazy"
            alt="seo-img"
            className="mb-3 modal-img rounded "
           Y onLoad={handleImageLoad}
            fluid
          />
        </Suspense>  */}
          <ImageComponent
            imageUrl={SeoImg}
            style={"mb-3 blurhash-img rounded"}
            altText={"Seo Img"}
          />
          {isImgLoaded && (
            <Button variant="danger" onClick={handleClose}>
              <Link
                to="/Contactus"
                className="text-decoration-none text-white"
                onClick={ScrollToTop}
              >
                Apply Now
              </Link>
            </Button>
          )}
        </Modal.Body>
      }
    </Modal>

    // <div
    //   className="d-flex align-items-center justify-content-center position-absolute z-3"
    //   data-aos="fade-up"
    // >
    //   {isVisible && (
    //     <div className="bg-primary d-flex flex-column align-items-center justify-content-center ">
    //       <button onClick={handleClose} className="text-end">
    //         X
    //       </button>
    //       <ImageComponent
    //         imageUrl={SeoImg}
    //         style={"mb-3 blurhash-img rounded"}
    //         altText={"Seo Img"}
    //       />
    //     </div>
    //   )}
    // </div>
  );
};

export default Popup;
