import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./shimmer.css";


const Shimmer = () => {
  return (
    // <div className="shimmer-lines-wrapper">
    //   {Array(lines)
    //     .fill()
    //     .map((_, index) => (
    //       <div key={index} className="shimmer-line"></div>
    //     ))}
    // </div>
    <Container className="d-flex flex-column gap-1 vh-100">
      <Row>
        <Col>
          <div className="shimmer-line  w-50 mb-3"></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="shimmer-line  w-75  mb-3"></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="shimmer-line  w-100 mb-3"></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="shimmer-line  w-75  mb-3"></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="shimmer-line  w-100 mb-3"></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="shimmer-line  w-75  mb-3"></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="shimmer-line  w-100 mb-3"></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="shimmer-line  w-100  mb-3"></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="shimmer-line  w-50 mb-3"></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="shimmer-line  w-100  mb-3"></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="shimmer-line  w-100 mb-3"></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="shimmer-line  w-75  mb-3"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default Shimmer;
